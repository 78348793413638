import * as React from "react"
import { Box, SxStyleProp } from "theme-ui"
import { useTrail, animated, config } from "react-spring"

export interface IPropsAnimatedHeading {
  delay?: number
  headline: string[]
  sx?: SxStyleProp
  visible: boolean
}

const AnimatedBox = animated(Box)

export const AnimatedHeading = ({
  delay,
  headline,
  sx,
  visible,
}: IPropsAnimatedHeading) => {
  const trail = useTrail(headline.length, {
    config: config.stiff,
    y: visible ? 0 : 100,
    from: { y: 100 },
    delay,
  })

  return trail.map(({ y }, index) => (
    <Box sx={{ overflow: "hidden", marginTop: "-0.2em", ...sx }} key={index}>
      <AnimatedBox
        sx={{ willChange: "transform" }}
        style={{
          transform: y.to((y) => `translate3d(0,${y}%,0)`),
        }}
      >
        <div>{headline[index]}</div>
      </AnimatedBox>
    </Box>
  ))
}
