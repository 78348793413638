import * as React from "react"
import { FC } from "react"
import { Box, Container, Grid } from "theme-ui"

export const GridOverlay: FC = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: "grid",
      }}
    >
      <Container sx={{ height: "100%" }}>
        <Grid variant="base" sx={{ height: "100%" }}>
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
          <Col />
        </Grid>
      </Container>
    </Box>
  )
}

const Col: FC = () => (
  <Box
    sx={{
      color: "text",
      opacity: 0.035,
    }}
  />
)
