import * as React from "react"
import { FC, ReactNode } from "react"

export interface IPropsOrphans {
  children: ReactNode | string
}

export const Orphans: FC<IPropsOrphans> = ({ children }) => {
  if (typeof children === "string") {
    const newChildren = children.replace(/ ([^ ]*)$/, "\u00a0$1")
    return newChildren
  }
  return children
}
