/** @jsx jsx */
import { FC } from "react"
import { jsx, Box, Container, Grid, Link, Text } from "theme-ui"

import { FooterLink } from "components/FooterLink"
import { Logo } from "icons/Logo"
import { Orphans } from "components/Orphans"

export const Footer: FC = () => {
  const marginStyle = {
    mb: [9, 12, 0],
  }
  return (
    <Box
      as="footer"
      sx={{
        bg: "backgroundSecondary",
        color: "textWhite",
        py: [14, null, null, null, 15, 16],
      }}
    >
      <Container>
        <Grid variant="base" sx={{ alignItems: "baseline" }}>
          <Box sx={{ gridColumn: ["span 12", null, "span 6"], ...marginStyle }}>
            <Link
              href="http://www.better.com"
              target="_blank"
              sx={{
                display: "inline-flex",
                mb: [5, null, "27px", 6, "33px"],
                color: "textWhite",

                ":focus-visible": {
                  outline: "1px dashed",
                },
              }}
            >
              <Logo
                sx={{
                  display: "inline-grid",
                  width: "100%",
                  height: "auto",
                  maxWidth: [160],
                }}
              />
            </Link>
            <Text
              variant="footerBody"
              sx={{
                position: "relative",
                top: [null, null, null, null, "-6px"],
                "::selection": {
                  bg: "backgroundSelectionWhite",
                },
              }}
            >
              <Orphans>
                Better Mortgage is a direct lender dedicated to providing a
                fast, transparent digital mortgage experience backed by superior
                customer support.
              </Orphans>
            </Text>
          </Box>
          <Box
            sx={{
              position: "relative",
              top: ["7px", null, "-2px", "-1px"],
              gridColumn: ["span 12", "span 6", "span 3", "span 3"],
              ...marginStyle,
            }}
          >
            <FooterLink
              to="/about"
              heading={["About Us"]}
              paragraph="See why we’re one of the fastest growing companies."
              delay={200}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              top: ["7px", null, "-2px", "-1px"],
              gridColumn: ["span 12", "span 6", "span 3", "span 3"],
            }}
          >
            <FooterLink
              to="/careers"
              heading={["Jobs"]}
              paragraph="Find back-end, front-end, data, security, and more."
              delay={300}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}
