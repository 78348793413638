/** @jsx jsx */
import * as React from "react"
import { FC } from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import { jsx, Box, Divider, Heading, Text } from "theme-ui"

import { AnimatedHeading } from "components/AnimatedHeading"

export interface IPropsFooterLink {
  delay?: number
  to: string
  heading: string[]
  paragraph: string
}

export const FooterLink: FC<IPropsFooterLink> = ({
  to,
  heading,
  paragraph,
  delay,
}) => {
  const [ref, inView, entry] = useInView({ threshold: 1 })
  const belowViewportText = !inView && entry && entry.intersectionRect.top > 0
  return (
    <Link
      to={to}
      ref={ref}
      sx={{
        textDecoration: "none",
        color: "textWhite",
        display: "block",

        ":focus-visible": {
          outline: "1px dashed",
        },
      }}
    >
      <Divider
        sx={{
          my: 0,
          height: "3px",
          border: 0,
          width: "100%",
          ["--thickness"]: "6px",
          color: "textWhite",
          bg: "hsla(0, 0%, 100%, 0.1)",
          backgroundImage:
            "linear-gradient(to bottom, currentColor 100%, transparent 0%)",
          backgroundSize: "0% var(--thickness)",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundPositionY: "bottom",
          transition: "background-size 0.25s var(--animation-bezier)",

          "a:hover &": {
            backgroundSize: "100% var(--thickness)",
            backgroundPositionX: "left",
            backgroundPositionY: "bottom",
          },
        }}
      />
      <Box sx={{ py: 7 }}>
        <Heading
          sx={{
            wordSpacing: "0.01em",
            letterSpacing: "tight3",
            fontWeight: 400,
            fontSize: [6, 7, 6, null, 7],
            mb: [3, 5, 6],
            color: "textWhite",
            "div::selection": {
              bg: "backgroundSelectionWhite",
            },
          }}
        >
          <AnimatedHeading
            headline={heading}
            visible={!belowViewportText}
            delay={delay}
          />
        </Heading>
        <Text
          variant="footerBody"
          sx={{
            pr: ["20%", null, null, null, 0],
            willChange: "color",
            transition: "color 0.25s var(--animation-bezier)",
            "::selection": {
              bg: "backgroundSelectionWhite",
            },

            "a:hover &": {
              color: "textWhite",
              transitionDuration: "0s",
            },
          }}
        >
          {paragraph}
        </Text>
      </Box>
    </Link>
  )
}
